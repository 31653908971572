import React from "react"
import { FaCode, FaHandshake, FaInfinity } from "react-icons/fa"
export default [
  {
    id: 1,
    icon: <FaCode className="service-icon" />,
    title: "full-stack development",
    text: `Powerful user centric apps built for the cloud`,
  },
  {
    id: 2,
    icon: <FaHandshake className="service-icon" />,
    title: "Mentoring",
    text: `1:1 code collaboration and pair programming on your project`,
  },
  {
    id: 3,
    icon: <FaInfinity className="service-icon" />,
    title: "Agile-DevOps",
    text: `Coaching and consultation to take your team and product to the next level`,
  },
]
