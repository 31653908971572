import React from "react"
import {
  FaCode,
  FaServer,
  FaDatabase,
  FaCloud,
  FaCodeBranch,
  FaRegQuestionCircle,
} from "react-icons/fa"
export default [
  {
    id: 1,
    icon: <FaCode className="service-icon" />,
    title: "front end",
    text: `HTML | CSS | JavaScript | Typescript | React | Redux | Next JS | Gatsby | Graphql`,
  },
  {
    id: 2,
    icon: <FaServer className="service-icon" />,
    title: "back end",
    text: `Node JS | Express | Python | Go | .Net | Docker | Kubernetes`,
  },
  {
    id: 3,
    icon: <FaDatabase className="service-icon" />,
    title: "databases",
    text: `SQL | MongoDB | Mongoose | PostgreSQL | MySQL | Redis`,
  },
  {
    id: 4,
    icon: <FaCloud className="service-icon" />,
    title: "cloud",
    text: `AWS | Google Cloud | Firebase| AWS Lamda`,
  },
  {
    id: 5,
    icon: <FaCodeBranch className="service-icon" />,
    title: "version control",
    text: `Git | Github | Gitlab`,
  },

  {
    id: 6,
    icon: <FaRegQuestionCircle className="service-icon" />,
    title: "testing",
    text: `Jest | Cypress | Mocha | Chai | Jasmine`,
  },
]
